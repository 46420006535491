<template>
  <StatisticComponent />
</template>


<script>

import StatisticComponent from "@/components/statistic/statisticComponent.vue";

export default {
  name: "StatisticView",
  components: {
    StatisticComponent,
  },
};
</script>