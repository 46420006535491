// eslint-disable-next-line import/no-cycle
import requestService from "../requestService";

const prefix = '/landings';

export default {
  async getLandings(params, name) {
    const response = await requestService.get(`${prefix}`,
        {...params}
        );
    return response?.data?.data;
  },
};
