export default {
    methods: {
        getColWidth(columnName) {
            const columnWidths = {
                landings_name: '500px',
            };
            return columnWidths[columnName] || '150px';
        },
        getSectionSuffix() {
            const suffixes = {
                dates: this.$t('statistic.by_date'),
                offers: this.$t('statistic.by_product'),
                streams: this.$t('statistic.by_stream'),
                landings: this.$t('statistic.by_landing'),
                advertorials: this.$t('statistic.by_advertorial'),
                prices: this.$t('statistic.by_price'),
                utms: this.$t('statistic.by_utm')
            };
            return suffixes[this.tab.value] || '';
        },
        getSectionValue() {
            const values = {
                dates: 'date',
                offers: 'offers_name',
                streams: 'streams_name',
                landings: 'landings_name',
                advertorials: 'advertorials_name',
                prices: 'price',
                utms: 'utm_key'
            };
            return values[this.tab.value] || '';
        },
    },
    computed: {
        statisticTrafficHeaders() {
            const baseHeaders = [
                {
                    text: this.$t('statistic.section') + ' ' + this.getSectionSuffix(),
                    value: this.getSectionValue(),
                    fixed: true,
                    width: this.getColWidth(this.getSectionValue()),
                    sortable: false
                },
                {text: this.$t('statistic.visit_advertorial'), value: 'advertorial_clicks',  sortable: false},
                {text: this.$t('statistic.conversion_from_advertorial'), value: 'advertorial_conversion',  sortable: false},
                {text: this.$t('statistic.cross_conversion_advertorial'), value: 'advertorial_conversion_through',  sortable: false},
                {text: this.$t('statistic.visit_product_page_without_form'), value: 'product_page_clicks',  sortable: false},
                {text: this.$t('statistic.conversion_from_product_page_without_form'), value: 'product_page_conversion',  sortable: false},
                {text: this.$t('statistic.cross_conversion_product_page_without_form'), value: 'product_page_conversion_through',  sortable: false},
                { text: this.$t('statistic.visit_landing'), value: 'landing_clicks', width: '100px', fixed: true, sortable: false },
                {text: this.$t('statistic.unique_visitors'), value: 'unique_clicks',  sortable: false},
                {text: this.$t('statistic.applications'), value: 'total_orders',  sortable: false},
                {text: this.$t('statistic.site_conversion'), value: 'CR',  sortable: false},
                {text: this.$t('statistic.in_process_at_cc'), value: 'in_progress_count',  sortable: false},
                {text: this.$t('statistic.rejected'), value: 'rejected_count',  sortable: false},
                {text: this.$t('statistic.confirmed'), value: 'confirmed_count',  sortable: false},
                {text: this.$t('statistic.approve_percentage'), value: 'approve',  sortable: false},
                {text: this.$t('statistic.not_purchased'), value: 'not_purchased_count',  sortable: false},
                {text: this.$t('statistic.awaiting_purchase'), value: 'shipped_count',  sortable: false},
                {text: this.$t('statistic.purchased'), value: 'purchased_count',  sortable: false},
                {text: this.$t('statistic.purchase_percentage'), value: 'purchased_percent',  sortable: false},
                {text: this.$t('statistic.profit'), value: 'actual_income',  sortable: false},
                {text: this.$t('statistic.epc'), value: 'EPC',  sortable: false}
            ];

            const utmHeaders = [
                {text: 'UTM Source', value: 'utm_source'},
                {text: 'UTM Medium', value: 'utm_medium'},
                {text: 'UTM Campaign', value: 'utm_campaign'},
                {text: 'UTM Content', value: 'utm_content'},
                {text: 'UTM Term', value: 'utm_term'},
            ];

            const additionalHeaders = [
                {text: 'Additional 1', value: 'additional_1',  sortable: false},
                {text: 'Additional 2', value: 'additional_2',  sortable: false},
                {text: 'Additional 3', value: 'additional_3',  sortable: false},
                {text: 'Additional 4', value: 'additional_4',  sortable: false},
                {text: 'Additional 5', value: 'additional_5',  sortable: false}
            ];

            const baseHeadersWithoutSection = baseHeaders.slice(1);

            return this.tab.value === 'utms' ? [...utmHeaders, ...baseHeadersWithoutSection, ...additionalHeaders] : baseHeaders;
        },
    }
}