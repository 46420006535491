// eslint-disable-next-line import/no-cycle
import requestService from "../requestService";

const prefix = '/statistics';

export default {
    async getStatisticTrafficSeller(params, name) {
        const response = await requestService.get(`${prefix}/seller/traffic/${name}`,
            {...params}
        );
        return response?.data?.data;
    },
    async getStatisticTraffic(params, name) {
        const response = await requestService.get(`${prefix}/traffic/${name}`,
            {...params}
        );
        return response?.data?.data;
    },
    async getStatisticUtmTraffic(params, payload) {
        const response = await requestService.post(`${prefix}/seller/nesting?start_date=${params.start_date}&end_date=${params.end_date}`, payload, {...params});
        return response?.data?.data;
    },
};
