<template>
  <v-expand-transition>
    <v-menu :close-on-content-click="false"
            :offset-y="true"
            :offset-x="true"
            transition="slide-x-transition"
            min-width="470"
            max-height="400"
            v-model="menuOpen"
    >
      <template v-slot:activator="{ on, attrs }">
      <span v-bind="attrs" v-on="on">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                class="px-md-2 mx-1"
                :small="$vuetify.breakpoint.mobile"
                v-bind="attrs"
                v-on="on"
                color="primary"
            >
              {{ $t('statistic.filters') }}
              <v-icon>{{ menuOpen ? 'mdi-minus' : 'mdi-plus' }}</v-icon>
            </v-btn>
          </template>
          <span>{{ $t('statistic.filters') }}</span>
        </v-tooltip>
        </span>
      </template>
      <v-card style="width: 470px">
        <v-card-text class="pa-0 pl-4">
          <v-row>
            <v-col class="mt-4" cols="11">
              <v-autocomplete
                  :items="offersList"
                  v-model="inputItems.offer_id"
                  :item-value="'id'"
                  :item-text="'name'"
                  :label="$t('form.offers')"
                  dense
                  outlined
                  persistent-hint
                  hide-details
              >
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="11">
              <v-autocomplete
                  :items="streamsList"
                  v-model="inputItems.stream_id"
                  :item-value="'id'"
                  :item-text="'name'"
                  :label="$t('form.streams')"
                  dense
                  outlined
                  persistent-hint
                  hide-details
              >
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="11">
              <v-autocomplete
                  :items="landingsList"
                  v-model="inputItems.landing_id"
                  :item-value="'id'"
                  :item-text="'name'"
                  :label="$t('form.landings')"
                  dense
                  outlined
                  persistent-hint
                  hide-details
              >
              </v-autocomplete>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="11">
                <v-text-field
                    :label="$t('offersCatalog.price')"
                    dense
                    outlined
                    color="primary"
                    hide-details="auto"
                    v-model="inputItems.price"
                    :maxLength="15"
                    class="auth-input"
                />
            </v-col>
          </v-row>
          <v-row class="pt-4">
            <v-col class="ml-3">
              {{ $t("form.UTMTags") }}
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="auto">
              <v-text-field
                  outlined
                  dense
                  color="primary"
                  v-model="inputItems.utm_source"
                  label="source"
                  :maxlength="15"
                  hide-details="auto"
              />
            </v-col>
            <v-col cols="auto">
              <v-text-field
                  outlined
                  dense
                  color="primary"
                  v-model="inputItems.utm_medium"
                  label="medium"
                  :maxlength="15"
                  hide-details="auto"
              />
            </v-col>
            <v-col cols="auto">
              <v-text-field
                  outlined
                  dense
                  color="primary"
                  v-model="inputItems.utm_campaign"
                  label="campaign"
                  :maxlength="15"
                  hide-details="auto"
              />
            </v-col>
            <v-col cols="auto" class="auth-input">
              <v-text-field
                  label="content"
                  dense
                  outlined
                  color="primary"
                  hide-details="auto"
                  v-model="inputItems.utm_content"
                  :maxLength="15"
                  class="auth-input"
              />
            </v-col>

            <v-col cols="auto">
              <v-text-field
                  label="term"
                  dense
                  outlined
                  color="primary"
                  hide-details="auto"
                  v-model="inputItems.utm_term"
                  :maxLength="15"
                  class="auth-input"
              />
            </v-col>

          </v-row>
          <v-row>
            <v-col class="ml-3">
              {{ $t("form.AddLabels") }}
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="auto">
              <v-text-field
                  outlined
                  dense
                  color="primary"
                  v-model="inputItems.additional_1"
                  label="sub1"
                  :maxlength="15"
                  hide-details="auto"
              />
            </v-col>
            <v-col cols="auto">
              <v-text-field
                  outlined
                  dense
                  color="primary"
                  v-model="inputItems.additional_2"
                  label="sub2"
                  :maxlength="15"
                  hide-details="auto"
              />
            </v-col>
            <v-col cols="auto">
              <v-text-field
                  outlined
                  dense
                  color="primary"
                  v-model="inputItems.additional_3"
                  label="sub3"
                  :maxlength="15"
                  hide-details="auto"
              />
            </v-col>
            <v-col cols="auto">
              <v-text-field
                  outlined
                  dense
                  color="primary"
                  v-model="inputItems.additional_4"
                  label="sub4"
                  :maxlength="15"
                  hide-details="auto"
              />
            </v-col>
            <v-col cols="auto">
              <v-text-field
                  label="sub5"
                  dense
                  outlined
                  color="primary"
                  hide-details="auto"
                  v-model="inputItems.additional_5"
                  :maxLength="15"
                  class="auth-input"
              />
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="pt-7">
          <v-row>
            <v-col cols="auto" class="pr-1 pt-0">
              <v-btn icon @click="refreshFilter"
              ><v-icon>mdi-refresh</v-icon></v-btn
              >
            </v-col>
            <v-col cols="auto" class="pl-1 pt-0" style="flex-grow: 1">
              <v-btn
                  block
                  class="btn-orange-bg"
                  @click="setFilters"
                  :disabled="!changed"
              >{{ $t("btn.apply") }}</v-btn
              >
            </v-col>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-menu>
  </v-expand-transition>
</template>

<script>
import { validationMixin } from "vuelidate";
import {validationForTitle} from "@/mixins/helpers";
import _ from "lodash";
import offersService from "@/services/request/offers/offersService";
import streamsService from "@/services/request/streams/streamsService";
import landingsService from "@/services/request/landings/landingsService";
export default {
  methods: {
    async getOffersList() {
      try {
        this.loading = true;
        const response = await offersService.getOffers();
        this.offersList = response.data;
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false;
      }
    },
    async getStreamsList() {
      try {
        this.loading = true;
        const response = await streamsService.getStreams();
        this.streamsList = response;
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false;
      }
    },
    async getLandingsList() {
      try {
        this.loading = true;
        const response = await landingsService.getLandings();
        this.landingsList = response;
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false;
      }
    },
    setFilters() {
        this.$emit('setFilters', this.inputItems);
    },
    refreshFilter() {
        this.inputItems = {};
        this.$emit('refreshFilter', this.inputItems);
    }
  },
  mixins: [validationMixin],
  async mounted() {
    await this.getOffersList();
    await this.getStreamsList();
    await this.getLandingsList();
    this.inputItems = _.cloneDeep(this.UTMs);
    this.copyItems = _.cloneDeep(this.inputItems);
  },
  data: () => ({
    menuOpen: false,
    inputItems: {},
    copyItems: {},
    changed: false,
    offersList: [],
    streamsList: [],
    landingsList: []
  }),
  props: {
    UTMs: {
      required: false,
      type: Object
    }
  },
  watch: {
    inputItems: {
      deep: true,
      handler() {
        this.changed = !_.isEqual(this.copyItems, this.inputItems);
      },
    },
  },
};
</script>

<style scoped>
.messageText {
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: #1c1c1c;
  display: flex;
}

.messageSubtitle {
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  color: #1c1c1c;
  margin-right: 12px;
}
</style>
